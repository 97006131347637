// react
import { MouseEvent } from 'react';

// router
import { Link as ReactRouterLink } from 'react-router-dom';

// @mui
// -- components
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MuiLink from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// config
import { locales } from '../../config/i18n';

// routes
// -- constants
import routes from '../../constants/routes';

// hooks
import useLocalization from '../../hooks/useLocalization';

// components
// -- shared
import Image from '../../components/image';

// ----------------------------------------------------------------------

const Header = () => {
    // hooks
    // -- localization
    const localization = useLocalization();

    return (
        <AppBar
            elevation={0}
            position='static'
            sx={{ background: 'transparent' }}
        >
            <Container maxWidth='xl'>
                <Toolbar sx={{ zIndex: 1 }}>
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{ width: '100%' }}
                    >
                        <MuiLink
                            to={routes.landing}
                            component={ReactRouterLink}
                        >
                            <Image
                                alt='sbisiali-logo'
                                src='/logo-sm-d.svg'
                                sx={{
                                    maxWidth: 115,
                                    height: 'auto',
                                    '& img': {
                                        objectFit: 'fill',
                                    },
                                }}
                            />
                        </MuiLink>

                        <Select
                            variant='outlined'
                            value={localization.locale}
                            sx={{
                                px: '16px',
                                height: '40px',
                                fontSize: '16px',
                                '.MuiSelect-icon': {
                                    color: 'common.white',
                                },
                                '.MuiSelect-outlined': {
                                    color: 'common.white',
                                },
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'common.white',
                                        borderWidth: '1px',
                                        borderRadius: '100px',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'common.white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'common.white',
                                    },
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                    {
                                        borderColor: 'common.white',
                                    },
                                '& .MuiSvgIcon-root': {
                                    color: 'common.white',
                                },
                            }}
                        >
                            {locales.map((locale) => {
                                return (
                                    <MenuItem
                                        value={locale.key}
                                        key={`footer-i18n-select-${locale.key}`}
                                        selected={
                                            localization.locale === locale.key
                                        }
                                        onClick={(
                                            e: MouseEvent<HTMLElement>
                                        ) => {
                                            e.preventDefault();

                                            localization.switchLanguage(locale);
                                        }}
                                    >
                                        {locale.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

// ----------------------------------------------------------------------

export default Header;
